import React from 'react';

const TalentIcon = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-293 385 24 24"
    style={{
      width: 24,
      height: 24,
    }}
    xmlSpace="preserve"
  >
    <style>
      {
        '.st0,.st2{fill:none;stroke:#000;stroke-width:1.2;stroke-miterlimit:10}.st2{stroke-width:.75}'
      }
    </style>
    <circle className="st0" cx={-280.9} cy={389} r={2.9} />
    <path
      className="st0"
      d="M-272.9 400.1v-2c0-2.7-5.4-4-8.1-4s-8.1 1.3-8.1 4v2"
    />
    <path
      d="M-280.9 396.1c.4 0 1.5 3.1 1.5 3.1s3.4.1 3.4.5-2.5 2.4-2.5 2.4.9 3.2.6 3.4c-.3.2-3.1-1.6-3.1-1.6s-2.7 1.8-3.1 1.6c-.4-.2.6-3.4.6-3.4s-2.5-2.1-2.5-2.4c0-.3 3.4-.5 3.4-.5s1.3-3.1 1.7-3.1z"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="st2"
      d="M-289.1 401c.3-.1 1.5 1.9 1.5 1.9s2.4-.4 2.4-.1c0 .3-1.4 2-1.4 2s1 2.1.8 2.3c-.2.2-2.3-.7-2.3-.7s-1.6 1.6-1.9 1.5c-.3-.1 0-2.4 0-2.4s-2-1.1-2-1.3c0-.2 2.3-.8 2.3-.8s.3-2.3.6-2.4zM-272.2 403.4s2.3.5 2.3.8c0 .2-2 1.3-2 1.3s.2 2.4 0 2.4c-.3.1-1.9-1.5-1.9-1.5s-2.1.9-2.3.7c-.2-.2.8-2.3.8-2.3s-1.4-1.7-1.4-2c0-.3 2.4.1 2.4.1s1.1-2 1.5-1.9c.2.1.6 2.4.6 2.4z"
    />
  </svg>
);

export default TalentIcon;
