import mockUsers from './assets/mockUsers.json';
import mockTeams from './assets/mockTeams.json';
import mockStrengths from './assets/mockStrengths.json';
import mockDomains from './assets/mockDomains.json';

const getUsersData = () => mockUsers;
const getTeamsData = () => mockTeams;
const getStrengthsData = () => mockStrengths;
const getDomainsData = () => mockDomains;

export { getUsersData, getTeamsData, getStrengthsData, getDomainsData };
