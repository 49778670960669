import React, { useContext } from 'react';
import { ApiDataContext } from '../../../modules/provider/ApiDataProvider';
import { Domain } from '../../../models/lib/Domain';

interface IStrengthBarProps {
  domainId: number;
}

const StrengthBar = ({ domainId }: IStrengthBarProps) => {
  const { domains } = useContext(ApiDataContext);
  return (
    <div
      style={{
        backgroundColor:
          domains &&
          domains.find((domain: Domain) => domain.id === domainId)?.hexColor,
      }}
      className="strengthsList__item-color"
    />
  );
};

export default StrengthBar;
