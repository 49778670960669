import React from 'react';
import {
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import './index.scss';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { ApiDataContext } from '../../../modules/provider/ApiDataProvider';
import { Strength, Team, User } from '../../../models';

interface ISearchBarResults {
  users?: User[];
  teams?: Team[];
  strengths?: Strength[];
}

const SearchBar = () => {
  const { users, strengths, teams } = React.useContext(ApiDataContext);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [showResults, setShowResults] = React.useState<boolean>(false);

  const resultsRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  const results = React.useMemo((): ISearchBarResults => {
    const cleanSearchQuery = searchQuery.toLowerCase();
    return cleanSearchQuery !== ''
      ? {
          users: users
            ?.filter(
              (user) =>
                user.firstName.toLowerCase().startsWith(cleanSearchQuery) ||
                user.lastName.toLowerCase().startsWith(cleanSearchQuery) ||
                user.emailAddress?.toLowerCase().startsWith(cleanSearchQuery),
            )
            .map((usr) => ({ ...usr, firstName: capitalize(usr.firstName) }))
            .sort((a, b) => (a.firstName < b.firstName ? -1 : 1)),
          teams: teams
            ?.filter((team) =>
              team.title.toLowerCase().startsWith(cleanSearchQuery),
            )
            .sort((a, b) => (a.title < b.title ? -1 : 1)),
          strengths: strengths?.filter((strength) =>
            strength.title.toLowerCase().startsWith(cleanSearchQuery),
          ),
        }
      : {};
  }, [searchQuery, strengths, teams, users]);

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (
        resultsRef?.current &&
        // @ts-ignore
        !resultsRef?.current.contains(event.target) &&
        searchInputRef?.current &&
        // @ts-ignore
        !searchInputRef?.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultsRef, searchInputRef]);

  return (
    <FormControl className="search-bar" fullWidth>
      <TextField
        autoComplete="off"
        label="Zoek op Strength, Team of Persoon..."
        variant="outlined"
        onChange={(event) => {
          setSearchQuery(event.target.value);
          if (!showResults) {
            setShowResults(true);
          }
        }}
        ref={searchInputRef}
      />
      <Paper
        className={`search-bar__popover ${
          showResults && searchQuery !== '' ? 'search-bar__popover-show' : ''
        }`}
        ref={resultsRef}
      >
        {results?.users?.length ||
        results?.teams?.length ||
        results?.strengths?.length ? (
          <List className="search-bar__popover__results" disablePadding>
            {results.users?.length ? (
              <li className="search-bar__popover__results__section">
                <ul>
                  <ListSubheader>Personen</ListSubheader>
                  {results.users.map((user) => (
                    <ListItem
                      key={user.id}
                      button
                      component={Link}
                      to={`/profile/${user.id}`}
                    >
                      <ListItemText>{`${user.firstName} ${user.lastName}`}</ListItemText>
                    </ListItem>
                  ))}
                </ul>
              </li>
            ) : null}
            {results.teams?.length ? (
              <li className="search-bar__popover__results__section">
                <ul>
                  <ListSubheader>Teams</ListSubheader>
                  {results.teams.map((team) => (
                    <ListItem
                      key={team.id}
                      button
                      component={Link}
                      to={`/teams/${team.id}`}
                    >
                      <ListItemText>{team.title}</ListItemText>
                    </ListItem>
                  ))}
                </ul>
              </li>
            ) : null}
            {results.strengths?.length ? (
              <li className="search-bar__popover__results__section">
                <ul>
                  <ListSubheader>Strengths</ListSubheader>
                  {results.strengths.map((strength) => (
                    <ListItem
                      key={strength.id}
                      button
                      component={Link}
                      to={`/strength/${strength.id}`}
                    >
                      <ListItemText>{strength.title}</ListItemText>
                    </ListItem>
                  ))}
                </ul>
              </li>
            ) : null}
          </List>
        ) : (
          <Typography className="search-bar__popover__no-results">
            Geen resultaten gevonden
          </Typography>
        )}
      </Paper>
    </FormControl>
  );
};
export default SearchBar;
