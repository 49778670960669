import { User, Team, Strength, Domain } from '../../models';
import * as fileReader from './lib/fileReader';

export default class MockRepository {
  fileReader;

  constructor() {
    this.fileReader = fileReader;
  }

  async getUsers(): Promise<User[]> {
    return this.fileReader.getUsersData();
  }

  async getTeams(): Promise<Team[]> {
    return this.fileReader.getTeamsData();
  }

  async getStrengths(): Promise<Strength[]> {
    return this.fileReader.getStrengthsData();
  }

  async getDomains(): Promise<Domain[]> {
    return this.fileReader.getDomainsData();
  }

  // eslint-disable-next-line class-methods-use-this
  async updateUser(user: User): Promise<User> {
    return user;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateTeam(team: Team): Promise<Team> {
    return team;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateStrength(strength: Strength): Promise<Strength> {
    return strength;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateDomain(domain: Domain): Promise<Domain> {
    return domain;
  }
}
