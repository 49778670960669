import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { IonSpinner, isPlatform } from '@ionic/react';
import { Redirect, useHistory } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { ApiDataContext } from '../../modules/provider/ApiDataProvider';

const iosOrAndroid = isPlatform('ios') || isPlatform('android');

const LoginScreen = () => {
  const {
    buildAuthorizeUrl,
    loginWithRedirect,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    logout,
  } = useAuth0();
  const history = useHistory();
  const { hydrate } = React.useContext(ApiDataContext);
  const [jwt, setJwt] = React.useState<string>();

  React.useEffect(() => {}, [getIdTokenClaims, isAuthenticated]);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      getIdTokenClaims()
        .then((idTokenClaim) => {
          // eslint-disable-next-line no-underscore-dangle
          const newJwt = idTokenClaim.__raw;
          axios.defaults.headers = {
            Authorization: `Bearer ${newJwt}`,
          };
          hydrate();
          setJwt(newJwt);
        })
        .catch((err) => {
          // eslint-disable
          console.log(err);
          logout();
        });
      return;
    }

    if (iosOrAndroid) {
      buildAuthorizeUrl().then((url) =>
        Browser.open({ url, windowName: '_self' }),
      );
      return;
    }

    loginWithRedirect({ ui_locales: 'nl' });
  }, [
    buildAuthorizeUrl,
    getIdTokenClaims,
    hydrate,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
  ]);

  const redirectTarget = React.useMemo(() => {
    const { pathname } = history.location;
    if (pathname && pathname.length > 1) {
      return pathname;
    }
    const params = new URLSearchParams(window.location.search);
    return params.get('pathname') || '/teams';
  }, [history.location]);

  return jwt ? <Redirect to={redirectTarget} /> : <IonSpinner />;
};

export default LoginScreen;
