import { List, ListItemText } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { Strength } from '../../../models';
import './StrengthsList.scss';
import StrengthBar from '../../atoms/StrengthBar';

const StrengthsList = ({ strengthsList }: { strengthsList: Strength[] }) => {
  const history = useHistory();

  return (
    <List className="strengthsList">
      {strengthsList.map((strength: Strength, index) => (
        <li className="strengthsList__item" key={`item-section-${strength.id}`}>
          <StrengthBar domainId={strength.domainId} />
          <ListItemText
            primary={`${index + 1}. ${strength.title}`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(`/strength/${strength.id}`);
            }}
          />
        </li>
      ))}
    </List>
  );
};

export default StrengthsList;
