import {
  AppBar,
  Box,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import BackButton from '../../atoms/backButton/BackButton';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#0F052B',
  },
  title: {
    fontVariant: 'small-caps',
  },
});

const Header = () => {
  const location = useLocation();
  const isHome = ['/', '/teams'].indexOf(location.pathname) >= 0;
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {isHome ? (
                <Typography variant="h2" className={classes.title}>
                  talents are meant to be shared.
                </Typography>
              ) : (
                <BackButton />
              )}
            </Grid>
            <Grid item>
              <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                <Typography variant="h2" className="title">
                  <Box fontWeight="fontWeightBold">Forte</Box>
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
