import React from 'react';

const TeamIcon = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="-293 385 24 24"
    style={{
      width: 24,
      height: 24,
    }}
    xmlSpace="preserve"
  >
    <style>{'.st0{fill:none;stroke:#000;stroke-miterlimit:10}'}</style>
    <circle className="st0" cx={-283.8} cy={392.2} r={3} />
    <path
      className="st0"
      d="M-284 397.1c-2.7 0-8.1 1.4-8.1 4.1v2h16.2v-2c0-2.8-5.4-4.1-8.1-4.1z"
    />
    <path d="M-276.8 397.1c1.5 1.1 2.5 2.5 2.5 4.4v2.2h5.1v-2.2c0-2.6-4.4-4.1-7.6-4.4zM-278.7 395.8c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6c-.6 0-1.1.1-1.5.4.6.9 1 2 1 3.2 0 1.2-.4 2.3-1 3.2.4.2.9.4 1.5.4z" />
  </svg>
);

export default TeamIcon;
