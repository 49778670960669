import React, { useContext } from 'react';
import { IonPage } from '@ionic/react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import ProfileHeader from './ProfileHeader';
import { ApiDataContext } from '../../modules/provider/ApiDataProvider';
import StrengthsList from '../../components/molecules/StrengthsList/StrengthsList';
import { Team } from '../../models';

const Profile: React.FC = () => {
  const { deleteUser, users, strengths, teams } = useContext(ApiDataContext);
  const history = useHistory();
  const userId = parseInt(useParams<{ userId: string }>().userId, 10);

  const user = React.useMemo(
    () => users?.find((currentUser) => currentUser.id === userId),
    [users, userId],
  );

  const orderedStrengths = React.useMemo(() => {
    if (!user?.strengthIds?.length || !strengths) {
      return [];
    }
    return (
      strengths.sort((a, b) => {
        const indexA = user?.strengthIds?.indexOf(a.id) || 0;
        const indexB = user?.strengthIds?.indexOf(b.id) || 0;

        return indexA - indexB;
      }) || []
    );
  }, [user, strengths]);

  const myTeams = React.useMemo(() => {
    if (!teams) {
      return [];
    }

    return teams
      .filter((team) => !!team.members.find((member) => member.id === userId))
      .sort((a, b) => (a.title < b.title ? -1 : 1));
  }, [teams, userId]);

  return (
    <IonPage>
      {user ? (
        <>
          <ProfileHeader user={user} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box marginTop={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h2">Strengths</Typography>

                    {strengths && (
                      <StrengthsList strengthsList={orderedStrengths} />
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box marginTop={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h2">Teams</Typography>
                    <List className="teamsList">
                      {myTeams.map((team: Team, index) => (
                        <li
                          className="strengthsList__item"
                          key={`item-section-${team.id}`}
                        >
                          <ListItemText
                            primary={`${index + 1}. ${team.title}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/teams/${team.id}`);
                            }}
                          />
                        </li>
                      ))}
                    </List>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-5"
                      onClick={() => {
                        if (window.confirm('Weet je het zeker?')) {
                          deleteUser(user);
                          history.push('/');
                        }
                      }}
                    >
                      Gebruiker verwijderen
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>Gebruiker niet gevonden</p>
      )}
    </IonPage>
  );
};

export default Profile;
