import { createTheme } from '@material-ui/core/styles';

import {
  blue,
  deepOrange,
  grey,
  red,
  pink,
  purple,
  teal,
} from '@material-ui/core/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: pink[500],
      light: pink[200],
    },
    secondary: {
      main: grey[500],
      light: grey[200],
    },
    // @ts-ignore
    executing: {
      main: purple.A700,
      light: purple[200],
    },
    influencing: {
      main: deepOrange.A700,
      light: deepOrange[200],
    },
    relationship: {
      main: blue.A700,
      light: blue[200],
    },
    strategic: {
      main: teal.A400,
      light: teal[200],
    },
    error: {
      main: red[500],
      light: red[200],
    },
    text: {
      primary: '#0F052B',
    },
    background: {
      default: '#E5E5E5',
    },
  },
  typography: {
    htmlFontSize: 10,
    h1: {
      fontSize: '2.6rem',
      fontWeight: 800,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 800,
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 800,
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 800,
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 800,
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 800,
    },
    fontFamily: ['Montserrat', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ',',
    ),
  },
});

export default theme;
