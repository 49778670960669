import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, List, Typography } from '@material-ui/core';
import './index.scss';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { capitalize } from 'lodash';
import { User } from '../../models';
import { ApiDataContext } from '../../modules/provider/ApiDataProvider';

const Strength: React.FC = () => {
  const { strengths, users, domains } = useContext(ApiDataContext);
  const params = useParams<{ strengthId: string }>();
  const strengthId = parseInt(params.strengthId, 10);

  const strength = React.useMemo(
    () =>
      strengths?.find((currentStrength) => currentStrength.id === strengthId),
    [strengths, strengthId],
  );
  const domain = React.useMemo(() => {
    if (!domains?.length || !strength) {
      return undefined;
    }
    return domains?.find(
      (currentDomain) => currentDomain.id === strength.domainId,
    );
  }, [domains, strength]);
  const usersWithThisStrength = React.useMemo((): User[] => {
    if (!strength || !users) {
      return [];
    }
    return users
      .filter((user) => user.strengthIds?.slice(0, 10).includes(strength.id))
      .map((usr) => ({ ...usr, firstName: capitalize(usr.firstName) }))
      .sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }, [strength, users]);
  return (
    <div className="page page-strength">
      {strength ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="order-md-2">
            <img
              className="page-strength__image"
              alt={`Illustratie van de strength ${strength.title}`}
              src={`${process.env.REACT_APP_BASE_URL}${strength.imageUrl}`}
            />
          </Grid>
          <Grid item xs={12} md={6} className="order-md-1">
            <Typography variant="h2">{strength.title}</Typography>
            {domain ? (
              <Typography color="textSecondary" gutterBottom>
                {domain.title}
              </Typography>
            ) : null}
            <Typography variant="body1">{strength.description}</Typography>
          </Grid>
          {usersWithThisStrength?.length ? (
            <Grid item xs={12} md={6} className="order-md-3">
              <Typography variant="h2" gutterBottom>
                Deze collega&apos;s hebben deze strength in hun top 10:
              </Typography>
              <List>
                {usersWithThisStrength.map((user) => (
                  <ListItem
                    button
                    component={Link}
                    to={`/profile/${user.id}`}
                    key={user.id}
                  >
                    <ListItemText
                      primary={`${user.firstName} ${user.lastName}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Typography variant="body1" className="page__intro">
          Strength niet gevonden
        </Typography>
      )}
    </div>
  );
};

export default Strength;
