import React, { useContext } from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import Header from '../components/molecules/header/header';
import { ApiDataContext } from '../modules/provider/ApiDataProvider';
import LoginScreen from './LoginScreen';

interface IDefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: IDefaultLayoutProps) => {
  const { strengths } = useContext(ApiDataContext);
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      <Header />
      {strengths ? (
        <Container>
          <>{children}</>
        </Container>
      ) : (
        <LoginScreen />
      )}
    </>
  );
};

export default DefaultLayout;
