import React, { useState, createContext, useEffect } from 'react';
import { User, Team, Strength, Domain } from '../../models';
import Repository from '../repository';
import MockRepository from '../mockrepository/repository';

const userRepository = new Repository<User>('user');
const teamRepository = new Repository<Team>('team');
const strengthRepository = new Repository<Strength>('strength');
const domainRepository = new Repository<Domain>('domain');

const mockRepository = new MockRepository();

interface IApiDataContext {
  hydrate: () => Promise<any>;
  users?: User[];
  teams?: Team[];
  strengths?: Strength[];
  domains?: Domain[];
  deleteUser(user: User): Promise<void>;
  updateUsers(user: Partial<User>[]): Promise<void>;
  deleteTeam(team: Team): Promise<void>;
  updateTeam(team: Partial<Team>): Promise<void>;
}

export const ApiDataContext = createContext<IApiDataContext>(
  {} as IApiDataContext,
);

const ApiDataProvider = ({ children }: any) => {
  const [users, setUsers] = useState<User[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [strengths, setStrengths] = useState<Strength[]>();
  const [domains, setDomains] = useState<Domain[]>();
  async function updateUsers(updatedUsers: User[]): Promise<void> {
    await Promise.all(
      updatedUsers.map(async (user) => userRepository.update(user)),
    );
    setUsers(await userRepository.find());
  }

  async function updateTeam(team: Team): Promise<void> {
    await teamRepository.update(team);
    setTeams(await teamRepository.find());
  }

  async function deleteTeam(team: Team): Promise<void> {
    await teamRepository.delete(team);
    setTeams(await teamRepository.find());
  }

  async function deleteUser(user: User): Promise<void> {
    await userRepository.delete(user);
    setUsers(await userRepository.find());
  }

  function hydrate() {
    return Promise.all([
      userRepository.find().then(setUsers),
      teamRepository.find().then(setTeams),
      strengthRepository.find().then(setStrengths),
      domainRepository.find().then(setDomains),
    ]);
  }

  function initMock() {
    mockRepository.getUsers().then(setUsers);
    mockRepository.getTeams().then(setTeams);
    mockRepository.getStrengths().then(setStrengths);
    mockRepository.getDomains().then(setDomains);
  }

  useEffect(() => {
    if (process.env.REACT_APP_MOCK_API === 'true') {
      initMock();
    }
  }, []);

  return (
    <ApiDataContext.Provider
      value={{
        hydrate,
        teams,
        users,
        strengths,
        deleteTeam,
        deleteUser,
        domains,
        updateUsers,
        updateTeam,
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;
