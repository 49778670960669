import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { IonPage } from '@ionic/react';
import {
  AppBar,
  makeStyles,
  List,
  TextField,
  Button,
  CircularProgress,
  Fab,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router';
import { capitalize } from 'lodash';
import { ApiDataContext } from '../../modules/provider/ApiDataProvider';
import TeamMember from './TeamMember';
import DomainScores from './DomainScores';
import { Team, User } from '../../models';
import { StrengthOccurrenceMap } from '../../models/lib/StrengthOccurrenceMap';
import AddUserDialog from './AddUserDialog';
import TeamIcon from '../../components/atoms/TeamIcon';
import TalentIcon from '../../components/atoms/TalentIcon';

interface ITabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        <Box paddingBottom={3} paddingTop={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const TeamView: React.FC = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const { users, teams, updateTeam, domains, strengths } =
    useContext(ApiDataContext);
  const params = useParams<{ teamId: string }>();
  const teamId = params.teamId === 'new' ? 0 : parseInt(params.teamId, 10);
  const team = useMemo<Team | undefined>(() => {
    if (teamId === 0) {
      return {
        title: '',
        description: '',
        members: [],
        id: 0,
        parentId: 0,
      } as Team;
    }
    return teams
      ? teams.find((currentTeam) => currentTeam.id === teamId)
      : undefined;
  }, [teams, teamId]);
  const [tabId, setTabId] = useState(0);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const toggleAddUserDialog = useCallback(() => {
    setIsOpen((oldIsOpen) => !oldIsOpen);
  }, [setIsOpen]);

  useEffect(() => {
    if (!team) {
      return;
    }
    setTitle(team.title);
    setDescription(team.description);
  }, [team]);

  const submitTeam = React.useCallback(async () => {
    await updateTeam({
      ...team,
      title,
      description,
    });
    history.push('/teams');
  }, [updateTeam, team, title, description, history]);

  const teamUsers = React.useMemo((): User[] => {
    if (!users || !team) {
      return [];
    }
    return (
      team.members.map((currentMember) =>
        users.find((user) => user.id === currentMember.id),
      ) as User[]
    )
      .map((usr) => ({ ...usr, firstName: capitalize(usr.firstName) }))
      .sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }, [team, users]);

  const strengthOccurrences =
    React.useMemo((): StrengthOccurrenceMap | null => {
      if (!strengths || !teamUsers || !team || !team.members.length) {
        return null;
      }
      return strengths.reduce((carry: StrengthOccurrenceMap, strength) => {
        const strengthId = parseInt(`${strength.id}`, 10);
        // eslint-disable-next-line no-param-reassign
        carry[strengthId] = {
          strengthName: strength.title,
          domainId: strength.domainId,
          // Strength occurrence in top 10
          positiveOccurrence: teamUsers.filter((user) =>
            user.strengthIds?.slice(0, 10).includes(strengthId),
          ).length,
          // Strength occurrence in bottom 5
          negativeOccurrence: teamUsers.filter((user) =>
            user.strengthIds?.slice(29).includes(strengthId),
          ).length,
        };
        return carry;
      }, {});
    }, [strengths, team, teamUsers]);

  const chartOptions = React.useMemo(
    () =>
      domains
        ? {
            labels: domains.map((currentDomain) => currentDomain.title),
            colors: domains.map((currentDomain) => currentDomain.hexColor),
            legend: { position: 'bottom' as 'bottom' },
            plotOptions: {
              pie: {
                donut: {
                  size: '33%',
                },
              },
            },
          }
        : null,
    [domains],
  );
  const chartSeries = React.useMemo(() => {
    if (!strengthOccurrences) {
      return null;
    }
    return Object.values(
      Object.values(strengthOccurrences).reduce(
        (carry: { [domainId: number]: number }, strengthOccurrence) => {
          const { domainId } = strengthOccurrence;
          if (!(domainId in carry)) {
            // eslint-disable-next-line no-param-reassign
            carry[domainId] = 0;
          }

          // eslint-disable-next-line no-param-reassign
          carry[domainId] += strengthOccurrence.positiveOccurrence;
          return carry;
        },
        {},
      ),
    );
  }, [strengthOccurrences]);

  const onTeamMemberRemove = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!team || !e.currentTarget.dataset.userId) {
        return;
      }
      const userId = parseInt(e.currentTarget.dataset.userId, 10);
      updateTeam({
        ...team,
        members: team.members.filter((member) => member.id !== userId),
      });
    },
    [team, updateTeam],
  );

  const useStyles = makeStyles({
    root: {
      borderRadius: '0',
    },
    tab: {
      padding: 0,
    },
    button: {
      left: '50%',
      marginRight: '1rem',
      marginLeft: -18,
    },
  });

  const classes = useStyles();

  if (!team) {
    return <CircularProgress />;
  }

  return (
    <IonPage>
      {chartOptions && chartSeries ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="donut"
            width="380"
          />
        </Box>
      ) : null}
      <div style={{ margin: '8px 0' }}>
        <TextField
          name="title"
          value={title}
          label="Titel"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          fullWidth
        />
      </div>
      <div style={{ margin: '8px 0' }}>
        <TextField
          fullWidth
          id="standard-multiline-flexible"
          label="Omschrijving"
          multiline
          maxRows={4}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </div>
      <div style={{ margin: '8px 0' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ display: 'block', marginLeft: 'auto' }}
          disabled={!title || !description}
          onClick={submitTeam}
        >
          Opslaan
        </Button>
      </div>
      <AppBar position="static" color="default" className={classes.root}>
        <Tabs
          value={tabId}
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setTabId(newValue);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Team" icon={<TeamIcon />} />
          <Tab label="Talenten" icon={<TalentIcon />} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabId} index={0}>
        <List>
          {teamUsers.map((teamMember) => (
            <TeamMember
              key={teamMember.id}
              user={teamMember}
              onRemove={onTeamMemberRemove}
            />
          ))}
        </List>
      </TabPanel>
      <TabPanel value={tabId} index={1}>
        {strengthOccurrences ? (
          <DomainScores strengthOccurrences={strengthOccurrences} />
        ) : (
          <CircularProgress />
        )}
      </TabPanel>
      <Box
        role="presentation"
        sx={{
          position: 'sticky',
          bottom: 16,
        }}
      >
        <Fab
          title="Gebruiker toevoegen"
          color="primary"
          className={classes.button}
          disabled={!team.id}
          onClick={toggleAddUserDialog}
        >
          <AddIcon />
        </Fab>
        {isOpen ? (
          <AddUserDialog onClose={toggleAddUserDialog} team={team} />
        ) : null}
      </Box>
    </IonPage>
  );
};

export default React.memo(TeamView);
