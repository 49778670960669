import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then((getters) => {
      getters.getCLS(onPerfEntry);
      getters.getFID(onPerfEntry);
      getters.getFCP(onPerfEntry);
      getters.getLCP(onPerfEntry);
      getters.getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
