import React, { ChangeEvent, useContext } from 'react';
import { IonPage } from '@ionic/react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { AlertProps } from '@material-ui/lab/Alert/Alert';
import XLSX from 'xlsx';
import {
  Grid,
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Fab,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router';
import { ApiDataContext } from '../../modules/provider/ApiDataProvider';
import { Team, Strength } from '../../models';
import './index.scss';
import SearchBar from '../../components/organisms/SearchBar';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    left: '50%',
    marginTop: '3rem',
    marginLeft: -18,
  },
  delete: {
    textAlign: 'right',
  },
});

interface ISheetRowData {
  Voornaam: string;
  Achternaam: string;
  'E-mailadres': string;
  [thema: string]: string;
}

const Teams: React.FC = () => {
  const { teams, updateUsers, strengths, deleteTeam } =
    useContext(ApiDataContext);
  const [alertProps, setAlertProps] = React.useState<AlertProps>();
  const history = useHistory();
  const classes = useStyles();

  const xlsxReader = React.useMemo(() => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target || !strengths) {
        return;
      }
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
      });

      // eslint-disable-next-line max-len
      const rows: ISheetRowData[] = (
        XLSX.utils as any
      ).sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]], {
        range: 'A3:ZZ999',
      });
      setAlertProps({
        children: 'Bezig met verwerken gegevens...',
        severity: 'info',
      });
      try {
        await updateUsers(
          rows
            .filter((row) => Object.keys(row).length > 10)
            .map((row) => {
              const strengthIds: number[] = [];
              for (let i = 1; i < 35; i += 1) {
                strengthIds.push(
                  (
                    strengths.find(
                      (strength) =>
                        strength.title.toLowerCase() ===
                        row[`Thema ${i}` as 'Thema 1'].toLowerCase(),
                    ) as Strength
                  ).id,
                );
              }
              return {
                firstName: row.Voornaam,
                lastName: row.Achternaam,
                emailAddress: row['E-mailadres'],
                active: true,
                strengthIds,
              };
            }),
        );
      } catch (err: unknown) {
        const { message } = err as Error;
        if (message) {
          setAlertProps({
            children: message,
            severity: 'error',
          });
        }
        setTimeout(() => {
          setAlertProps(undefined);
        }, 7000);
      }
      setAlertProps({
        children: 'Sheet verwerkt',
        severity: 'success',
      });
      setTimeout(() => {
        setAlertProps(undefined);
      }, 7000);
    };
    return reader;
  }, [strengths, updateUsers]);
  if (!teams) {
    return <CircularProgress />;
  }

  return (
    <>
      <IonPage className="page-teams">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.root}>
              <SearchBar />
            </Box>
          </Grid>
        </Grid>
        <Typography variant="h1">Teams</Typography>
        <p>Bekijk teams en voeg team leden toe.</p>
        <Grid container spacing={3}>
          {teams
            .sort((a, b) => (a.title < b.title ? -1 : 1))
            .map((team: Team) => (
              <Grid item key={team.id} xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Link
                      to={`/teams/${team.id}`}
                      key={team.id}
                      className={classes.link}
                    >
                      <Card>
                        <Box p={2}>
                          <Grid container>
                            <Grid item xs={10}>
                              <Typography variant="h2">
                                {team.title}!
                              </Typography>
                              <Typography variant="subtitle1">
                                {team.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.delete}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={(e) => {
                                  deleteTeam(team);
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Box
          role="presentation"
          sx={{
            position: 'sticky',
            bottom: 16,
          }}
        >
          <Fab
            title="Team toevoegen"
            color="primary"
            className={classes.button}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push('/teams/new');
            }}
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box marginTop={3} marginBottom={3}>
          <Card>
            <CardContent>
              <Typography variant="h3">Updaten van Werknemers</Typography>
              <p>
                Upload de .xlsx file vanuit https://gallupstrengthscenter.com/
                om nieuwe werknemers toe te voegen.
              </p>
              <div style={{ paddingTop: 10 }}>
                {alertProps ? (
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  <MuiAlert elevation={6} variant="filled" {...alertProps} />
                ) : (
                  <Button variant="contained">
                    Upload .xlsx
                    <input
                      type="file"
                      name="files"
                      className="button"
                      style={{ marginLeft: '1rem' }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // const data = e.target.result;
                        if (!e.target.files) {
                          return;
                        }
                        xlsxReader.readAsBinaryString(e.target.files[0]);
                      }}
                      // @ts-ignore
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </Button>
                )}
              </div>
              <p>
                <strong>Let op: </strong>Gebruik de Nederlandse versie van de
                export uit Gallup.
              </p>
            </CardContent>
          </Card>
        </Box>
      </IonPage>
    </>
  );
};

export default Teams;
