import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import React from 'react';
import { App as CapApp } from '@capacitor/app';
import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import Profile from './views/Profile';
import Teams from './views/Teams';
import Team from './views/Team';
import Strength from './views/Strength';

/* Theme variables */
import './styles/main.scss';
import DefaultLayout from './layouts/DefaultLayout';

const App: React.FC = () => {
  const { handleRedirectCallback } = useAuth0();

  React.useEffect(() => {
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (
        url.includes('state') &&
        (url.includes('code') || url.includes('error'))
      ) {
        await handleRedirectCallback(url);
      }

      await Browser.close();
    });
  }, [handleRedirectCallback]);

  return (
    <Router>
      <DefaultLayout>
        <Switch>
          <Route exact path="/teams" component={Teams} />
          <Route exact path="/teams/:teamId" component={Team} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/:userId" component={Profile} />
          <Route exact path="/strength/:strengthId" component={Strength} />
          <Route>
            <Redirect to="/teams" />
          </Route>
        </Switch>
      </DefaultLayout>
    </Router>
  );
};

export default App;
