import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import React, { useContext } from 'react';
import { capitalize } from 'lodash';
import { ApiDataContext } from '../../../modules/provider/ApiDataProvider';
import { Team, User } from '../../../models';
import './index.scss';

interface IAddUserDialogProps {
  team: Team;
  onClose: () => void;
}

const AddUserDialog = ({ team, onClose }: IAddUserDialogProps) => {
  const { users } = useContext(ApiDataContext);
  const { updateTeam } = useContext(ApiDataContext);
  const [query, setQuery] = React.useState('');

  const onAdd = React.useCallback(
    (user: User): void => {
      updateTeam({ ...team, members: [...team.members, { id: user.id }] });
      onClose();
    },
    [onClose, team, updateTeam],
  );
  const availableUsers = React.useMemo(() => {
    if (!users) {
      return [];
    }
    const selectedUserIds = team.members.map((member) => member.id);
    return users
      .filter((user) => {
        if (selectedUserIds.indexOf(user.id) > 0) {
          return false;
        }
        if (
          query &&
          user.firstName.toLowerCase().indexOf(query) === -1 &&
          user.lastName.toLowerCase().indexOf(query) === -1
        ) {
          return false;
        }
        return true;
      })
      .map((usr) => ({ ...usr, firstName: capitalize(usr.firstName) }))
      .sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }, [query, team.members, users]);

  if (!users) {
    return <CircularProgress />;
  }

  return (
    <Dialog onClose={onClose} open className="team-add-user-dialog">
      <DialogTitle>Teamleden toevoegen</DialogTitle>
      <div style={{ paddingLeft: 10 }}>
        <TextField
          id="outlined-basic"
          label="Zoeken..."
          variant="outlined"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value.toLowerCase());
          }}
          autoFocus
        />
      </div>
      <List style={{ minWidth: 300 }}>
        {availableUsers.map((user) => (
          <ListItem button onClick={() => onAdd(user)} key={user.id}>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${user.firstName} ${user.lastName}`} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default AddUserDialog;
