import React from 'react';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderColor: 'white',
    color: 'white',
  },
});

const BackButton = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      variant="outlined"
      color="secondary"
      onClick={history.goBack}
      startIcon={<ArrowBackIcon />}
    >
      Terug
    </Button>
  );
};

export default BackButton;
