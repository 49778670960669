import React from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { StrengthOccurrenceMap } from '../../../models/lib/StrengthOccurrenceMap';
import './index.scss';
import StrengthBar from '../../../components/atoms/StrengthBar';

interface IDomainScoresProps {
  strengthOccurrences: StrengthOccurrenceMap;
}

const DomainScores = ({ strengthOccurrences }: IDomainScoresProps) => {
  const [sort, setSort] = React.useState({ prop: 'strengthName', asc: true });

  const sortedStrengthOccurrences = React.useMemo(() => {
    return Object.values(strengthOccurrences).sort((a, b) => {
      // @ts-ignore
      return (a[sort.prop] < b[sort.prop] ? -1 : 1) * (sort.asc ? 1 : -1);
    });
  }, [sort, strengthOccurrences]);

  return (
    <List
      className="domain-scores"
      subheader={
        <ListSubheader
          style={{
            backgroundColor: '#E5E5E5',
            display: 'flex',
            marginBottom: 4,
          }}
        >
          <Button
            type="button"
            onClick={() => setSort({ prop: 'strengthName', asc: !sort.asc })}
          >
            Strength
          </Button>
          <Button
            type="button"
            title="Top 10"
            style={{ position: 'absolute', right: 50 }}
            onClick={() =>
              setSort({
                prop: 'positiveOccurrence',
                asc: !sort.asc,
              })
            }
          >
            +
          </Button>
          <Button
            type="button"
            title="Bottom 5"
            style={{ position: 'absolute', right: 0 }}
            onClick={() =>
              setSort({
                prop: 'negativeOccurrence',
                asc: !sort.asc,
              })
            }
          >
            -
          </Button>
        </ListSubheader>
      }
    >
      {sortedStrengthOccurrences.map((strengthOccurrence) => (
        <ListItem key={strengthOccurrence.strengthName}>
          <StrengthBar domainId={strengthOccurrence.domainId} />
          <ListItemText primary={strengthOccurrence.strengthName} />
          <ListItemSecondaryAction>
            <ListItemText
              className="domain-scores__occurrence"
              primary={strengthOccurrence.positiveOccurrence}
              style={{ width: 40 }}
            />
            <ListItemText
              className="domain-scores__occurrence domain-scores__occurrence--negative"
              primary={strengthOccurrence.negativeOccurrence || 0}
              style={{ width: 40 }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default DomainScores;
