import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { isPlatform } from '@ionic/react';
import App from './App';
import theme from './theme';
import * as serviceWorkerRegistration from './modules/browser/serviceWorkerRegistration';
import reportWebVitals from './modules/browser/reportWebVitals';
import ApiDataProvider from './modules/provider/ApiDataProvider';

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env;

if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error('Missing required environment variables');
}
const iosOrAndroid = isPlatform('ios') || isPlatform('android');
ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={
        iosOrAndroid
          ? `nl.synetic.forte://${REACT_APP_AUTH0_DOMAIN}/capacitor/nl.synetic.forte/callback`
          : `${window.location.origin}?pathname=${window.location.pathname}`
      }
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <ApiDataProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ApiDataProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
