import axios from 'axios';

export default class Repository<T extends { id: number }> {
  url: string;

  constructor(type: string) {
    if (!process.env.REACT_APP_BASE_URL) {
      throw new Error('REACT_APP_BASE_URL not set in .env.local');
    }
    this.url = `${process.env.REACT_APP_BASE_URL}/${type}`;
    Object.freeze(this);
  }

  async find(): Promise<T[]> {
    return axios.get(this.url).then((res) => res.data);
  }

  async findById(id: string): Promise<T> {
    return axios.get(`${this.url}/${id}`).then((res) => res.data);
  }

  async delete(item: T): Promise<T> {
    return axios.delete(`${this.url}/${item.id}`).then((res) => res.data);
  }

  async update(item: T): Promise<T> {
    return axios
      .post(`${this.url}${item.id ? `/${item.id}` : ''}`, item)
      .then((res) => res.data);
  }
}
